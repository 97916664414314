import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hello, Homosapein o/
    </Text>
    <p>{`I'm Naveen. Welcome to my tiny little space on the web :) `}</p>
    <p>{`I do stuffs with computers and sometimes write about it here. I also like to write about various life lessons
that I learn and sometimes use this place to brag about myself :P, Besides spending time with computers I love to:`}</p>
    <ul>
      <li parentName="ul">{`Go on treks, runs and on sniffari's with Bruno 🐶`}</li>
      <li parentName="ul">{`Read books, especially fantasy fiction -  `}<em parentName="li">{`Brandon Sanderson ❤️`}</em>{` (`}<em parentName="li">{`bye bye world :3`}</em>{`)`}</li>
      <li parentName="ul">{`Watch anything animated (`}<em parentName="li">{`name a animated movie, I bet I must've watched it`}</em>{` 😉)`}</li>
      <li parentName="ul">{`I also love desserts ^^`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      